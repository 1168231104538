
export default {
  name: "BaseInputText",
  props: {
    value: {
      type: String,
    },
    inputStyles: {
      type: String,
      required: false,
      default: "",
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
