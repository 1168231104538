import Ajv, { DefinedError, ErrorObject } from "ajv"
import addFormats from "ajv-formats"
import addErrors from "ajv-errors"

// Use AJV for validation using JSON Schema standard. Add modules for specifying
// string formats and custom error messages.
const ajv = new Ajv({ allErrors: true })
addFormats(ajv)
addErrors(ajv)

/**
 * Validate the object against the schema string from the server and return
 * the Joi Validation result.
 */
export function validateObject(schema: any, target: object): ErrorObject[] {
  const validate = ajv.compile(schema)
  validate(target)
  const errors = validate.errors
  // todo: may want this to return { boolean, empty list } instead of null when there are no errors?
  return errors
}

/**
 * Filter a DefinedError list for errors related to a specific field. By
 * instancePath.
 */
export function parseFieldErrors(
  errorList: DefinedError[],
  fieldPath: string
): string[] {
  if (errorList == null) return []
  const fieldErrorList = errorList
    .filter((e) => {
      // This uses `instancePath` in ajv 8+?
      return e.dataPath === fieldPath
    })
    .map((e) => e.message)
  return fieldErrorList
}
