
import Vue from "vue"

import LoadingIcon from "@/components/LoadingIcon.vue"

export default Vue.extend({
  name: "BaseModalListAction",
  components: { LoadingIcon },
  props: {
    actionText: {
      type: String,
      required: true,
    },
    actionHandler: {
      type: Function,
      required: false,
      default: () => undefined,
    },
    iconPath: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
