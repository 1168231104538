
import Vue, { PropType } from "vue"
import { mdiCalendar, mdiDelete, mdiDownload, mdiEmailSend } from "@mdi/js"
import { v4 as uuidv4 } from "uuid"
import { DateTime } from "luxon"

// Local
import IconSvg from "@/components/IconSvg.vue"
import { getReportIcon } from "@/reports"
import { enableDefaultTooltips } from "@/tooltips"
import { ReportData } from "@/models/report-data"
import LoadingIcon from "@/components/LoadingIcon.vue"

export default Vue.extend({
  name: "ReportListDataItem",
  components: { LoadingIcon, IconSvg },
  inject: ["deleteReportHandler", "emailReportHandler"],
  props: {
    item: {
      type: Object as PropType<ReportData>,
      required: true,
    },
  },
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      downloadLinkId: uuidv4(),
      deleteButtonId: uuidv4(),
      emailButtonId: uuidv4(),
      mdiCalendar,
      mdiDelete,
      mdiDownload,
      mdiEmailSend,
      getReportIcon,
      showTip: null,
      deleteInProgress: false,
      emailInProgress: false,
    }
  },
  computed: {
    readableTimestamp() {
      return DateTime.fromISO(this.item.timestampCreated).toLocaleString(
        DateTime.DATETIME_MED
      )
    },
  },
  methods: {
    async deleteReport() {
      this.deleteInProgress = true
      try {
        await this.deleteReportHandler(this.item.id)
      } finally {
        this.deleteInProgress = false
      }
    },
    async emailReport() {
      this.emailInProgress = true

      try {
        await this.emailReportHandler(this.item.id)
      } finally {
        this.emailInProgress = false
      }
    },
  },
  mounted() {
    // Mount tooltips
    const downloadLink = document.querySelector(
      `[data-uuid="${this.downloadLinkId}"]`
    )
    const deleteButton = document.querySelector(
      `[data-uuid="${this.deleteButtonId}"]`
    )
    const emailButton = document.querySelector(
      `[data-uuid="${this.emailButtonId}"]`
    )
    enableDefaultTooltips([downloadLink, deleteButton, emailButton])
  },
})
