
import Vue from "vue"
import { DateTime } from "luxon"

export default Vue.extend({
  name: "BaseInputTime",
  props: {
    value: {
      type: String,
    },
    inputStyles: {
      type: String,
      required: false,
      default: "",
    },
    minTime: {
      type: String,
      required: false,
      default: undefined,
    },
    maxTime: {
      type: String,
      required: false,
      default: undefined,
    },
    includeSeconds: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cTime(): string | undefined {
      return this.parseTime(this.value)
    },
    cMinTime(): string | undefined {
      return this.parseTime(this.minTime)
    },
    cMaxTime(): string | undefined {
      return this.parseTime(this.maxTime)
    },
  },
  methods: {
    parseTime(input: string): string {
      const format = this.includeSeconds ? "HH:mm:ss" : "HH:mm"
      const time = DateTime.fromISO(input).toFormat(format)
      return time
    },
  },
})
