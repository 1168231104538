
import Vue from "vue"
import { mdiChevronDown } from "@mdi/js"

export default Vue.extend({
  name: "BaseSelection",
  inheritAttrs: false,
  props: {
    value: {
      default: undefined,
    },
    optionValues: {
      type: Array,
      required: true,
    },
    optionLabels: {
      type: Array,
      required: false,
      default: null,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      mdiChevronDown,
    }
  },
})
