
import Vue from "vue"
export default Vue.extend({
  name: "ReportCardHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
  },
})
