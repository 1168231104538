
import Vue from "vue"

export default Vue.extend({
  name: "BaseRadio",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      default: "",
    },
  },
  computed: {
    isChecked(): boolean {
      return this.modelValue == this.value
    },
  },
})
