
import Vue, { PropType } from "vue"
import { v4 as uuidv4 } from "uuid"
import { mdiCalendarClock, mdiPencil, mdiFileUpload, mdiClock } from "@mdi/js"

// Local
import IconSvg from "@/components/IconSvg.vue"
import { getReportIcon } from "@/reports"
import { enableDefaultTooltips } from "@/tooltips"
import { ReportConfig } from "@/models/report-config"
import { ReportSpec } from "@/models/report-spec"
import { DateTime } from "luxon"

export default Vue.extend({
  name: "ReportListConfigItem",
  components: { IconSvg },
  props: {
    config: {
      type: Object as PropType<ReportConfig>,
      required: true,
    },
    spec: {
      type: Object as PropType<ReportSpec>,
      required: true,
    },
    editHandler: {
      type: Function as PropType<(config: ReportConfig) => void>,
      required: true,
    },
    generateHandler: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      editButtonId: uuidv4(),
      generateButtonId: uuidv4(),
      mdiCalendarClock,
      mdiClock,
      mdiPencil,
      mdiFileUpload,
      getReportIcon,
    }
  },
  computed: {
    readableTimestamp() {
      return DateTime.fromISO(this.config.timestampLastModified).toLocaleString(
        DateTime.DATETIME_MED
      )
    },
  },
  mounted() {
    // Mount tooltips
    const editButton = document.querySelector(
      `[data-uuid="${this.editButtonId}"]`
    )
    const generateButton = document.querySelector(
      `[data-uuid="${this.generateButtonId}"]`
    )
    enableDefaultTooltips([editButton, generateButton])
  },
})
