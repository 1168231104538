
import Vue from "vue"
// import { mdiProgressWrench } from "@mdi/js"

// Local
// import IconSvg from "./IconSvg.vue"

export default Vue.extend({
  name: "LoadingIcon",
  // components: { IconSvg },
  // props: {},
  // data() {
  //   return {
  //     mdiProgressWrench,
  //   }
  // },
})
