import tippy from "tippy.js"

/**
 * Enables text tooltips based on attribute data-tippy-content with default
 * theme and default animation.
 */
export function enableDefaultTooltips(elements: Element[]): void {
  tippy(elements, {
    theme: "default",
    animation: "default",
    duration: [150, 150],
    delay: [500, null],
  })
}
