
import Vue from "vue"
export default Vue.extend({
  name: "IconSvg",
  props: {
    path: {
      type: String,
      required: true,
    },
  },
})
