
import Vue from "vue"
import { mapGetters, mapActions, mapState } from "vuex"
import { DateTime } from "luxon"

// Local Libs
import { Util } from "@/helpers"
// Local Components
import ReportsListLayout from "@/views/ReportsListLayout.vue"
import ReportConfigLayout from "@/views/ReportConfigLayout.vue"
import ReportModalGenerateActions from "@/components/ReportModalGenerateActions.vue"
import WrapperMaxWidth from "@/components/WrapperMaxWidth.vue"
import TransitionSlideInOutRight from "@/components/TransitionSlideInOutRight.vue"
import TransitionSlideInOutLeft from "@/components/TransitionSlideInOutLeft.vue"
import BaseModalListAction from "@/components/BaseModalListAction.vue"
// Local Models
import { ReportConfig } from "@/models/report-config"
import AlertInfo from "@/components/AlertInfo.vue"
import { ReportData } from "@/models/report-data"

export default Vue.extend({
  name: "ReportsContainer",
  components: {
    AlertInfo,
    BaseModalListAction,
    TransitionSlideInOutLeft,
    TransitionSlideInOutRight,
    WrapperMaxWidth,
    ReportsListLayout,
    ReportConfigLayout,
    ReportModalGenerateActions,
  },
  provide() {
    return {
      deleteReportHandler: this.deleteReportDataWrapper,
      emailReportHandler: this.emailReportWrapper,
    }
  },
  data() {
    return {
      selectedSpec: null,
      selectedConfig: null,
      showGenerateActions: false,
      indexForConfigGenerateActions: null,
      emailInProgress: false,
      downloadInProgress: false,
    }
  },
  computed: {
    ...mapGetters("reportConfigsModule", { reportConfigs: "getAll" }),
    ...mapGetters("reportSpecModule", { reportSpecs: "allSpecsWithId" }),
    ...mapGetters("reportDataModule", {
      reportData: "getAll",
      isGeneratingReport: "isGeneratingReport",
    }),
    ...mapState("firetableModule", ["rootUserData", "currentUser"]),
    defaultSiteKey(): string | undefined {
      return this.rootUserData?.defaultSiteKey
    },
  },
  methods: {
    clearSelectedConfig(): void {
      this.selectedConfig = null
    },
    clearSelectedSpec(): void {
      this.selectedSpec = null
    },
    clearSelection(): void {
      this.clearSelectedConfig()
      this.clearSelectedSpec()
    },
    async generateReportHandler(
      reportConfig: ReportConfig,
      download?: boolean
    ): Promise<ReportData | undefined> {
      const report = await this.generate({
        siteKey: this.defaultSiteKey,
        reportConfig: reportConfig,
      })
      if (download === true && report != null) {
        window.location.href = report.downloadURL
      }
      return report
    },
    navHome(): void {
      this.$router.push("/home")
    },
    navCreateNewConfig(specification): void {
      this.selectedSpec = specification
      window.scrollTo(0, 0)
    },
    navEditConfig(configuration: ReportConfig): void {
      this.selectedSpec = this.reportSpecs[configuration.type]
      this.selectedConfig = configuration
      window.scrollTo(0, 0)
    },
    async saveConfig(configuration: ReportConfig): Promise<void> {
      // todo: add loading / saving indicator to UI
      configuration.timestampLastModified = DateTime.now().toISO()
      await this.dispatchSaveConfig({
        config: configuration,
        siteKey: this.defaultSiteKey,
      })
      Util.logOnlyOnDev("Saved!")
    },
    async deleteConfig(configuration: ReportConfig): Promise<void> {
      // todo: add loading / deleting indicator to UI
      await this.dispatchDeleteConfig({
        config: configuration,
        siteKey: this.defaultSiteKey,
      })
      Util.logOnlyOnDev("Deleted!")
    },
    toggleModalGenerateActions(): void {
      this.showGenerateActions = !this.showGenerateActions
    },
    generateActions(configIndex: number): void {
      this.toggleModalGenerateActions()
      this.indexForConfigGenerateActions = configIndex
    },
    /**
     * Used to generate report and email download link from a saved config.
     */
    async generateAndEmailFromSavedConfig() {
      const index = this.indexForConfigGenerateActions
      if (typeof index !== "number") {
        throw Error(
          "Unexpected value for indexForConfigGenerateActions. Not a number."
        )
      }
      Util.logOnlyOnDev(`Sending Download Link Email #${index}`)
      const config = this.reportConfigs[index]
      await this.generateNewReportAndEmail(config)
    },
    /**
     * Generates a new report and sends an email to the owner.
     */
    async generateNewReportAndEmail(config: ReportConfig) {
      Util.logOnlyOnDev("Generating report for email")
      this.emailInProgress = true
      const reportData: ReportData = await this.generateReportHandler(config)
      Util.logOnlyOnDev("Done generating")

      Util.logOnlyOnDev("Sending email")
      await this.emailReportDownloadURL({
        siteKey: this.defaultSiteKey,
        reportDataId: reportData.id,
      })
      Util.logOnlyOnDev("Done sending")
      this.emailInProgress = false
    },
    /**
     * Used to email a downloadURL of a previously created report.
     */
    async emailReportWrapper(reportDataId: string): Promise<void> {
      await this.emailReportDownloadURL({
        reportDataId: reportDataId,
        siteKey: this.defaultSiteKey,
      })
    },
    async downloadExcelFile(): Promise<void> {
      this.downloadInProgress = true
      const index = this.indexForConfigGenerateActions
      if (typeof index !== "number") {
        throw Error(
          "Unexpected value for indexForConfigGenerateActions. Not a number."
        )
      }
      Util.logOnlyOnDev(`Downloading Excel File #${index}...`)
      const config = this.reportConfigs[index]
      await this.generateReportHandler(config, true)
      // Clear reportConfigs index selection.
      this.indexForConfigGenerateActions = null
      this.downloadInProgress = false
      // Close the modal.
      this.toggleModalGenerateActions()
    },
    async deleteReportDataWrapper(reportDataId: string) {
      if (this.defaultSiteKey != null) {
        await this.deleteReportData({
          siteKey: this.defaultSiteKey,
          reportDataId: reportDataId,
        })
      }
      Util.logOnlyOnDev(`Finished deleting id: ${reportDataId}`)
    },
    ...mapActions("reportSpecModule", ["fetchReportSpecs"]),
    ...mapActions("reportConfigsModule", {
      fetchReportConfigs: "fetchReportConfigs",
      dispatchSaveConfig: "save",
      dispatchDeleteConfig: "delete",
    }),
    ...mapActions("reportDataModule", [
      "fetchReportDataList",
      "generate",
      "deleteReportData",
      "emailReportDownloadURL",
    ]),
  },
  watch: {
    defaultSiteKey() {
      if (typeof this.defaultSiteKey === "string") {
        this.fetchReportSpecs(this.defaultSiteKey)
        this.fetchReportConfigs({
          siteKey: this.defaultSiteKey,
          uid: this.currentUser.uid,
        })
        this.fetchReportDataList({
          siteKey: this.defaultSiteKey,
          uid: this.currentUser.uid,
        })
      }
    },
  },
  mounted() {
    if (typeof this.defaultSiteKey === "string") {
      this.fetchReportSpecs(this.defaultSiteKey)
      this.fetchReportConfigs({
        siteKey: this.defaultSiteKey,
        uid: this.currentUser.uid,
      })
      this.fetchReportDataList({
        siteKey: this.defaultSiteKey,
        uid: this.currentUser.uid,
      })
    }
  },
})
