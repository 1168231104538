// Libs
import { Component } from "vue"
// Local
import BaseCheckbox from "@/components/BaseCheckbox.vue"
import BaseInputNumberInteger from "@/components/BaseInputNumberInteger.vue"
import BaseInputNumberFloat from "@/components/BaseInputNumberFloat.vue"
import BaseInputText from "@/components/BaseInputText.vue"
import BaseSelection from "@/components/BaseSelection.vue"
import BaseInputDateTime from "@/components/BaseInputDateTime.vue"

export const clientUIFields = [
  "boolean",
  "float",
  "integer",
  "string",
  "selection",
  "timestamp",
] as const
export type ClientUIFields = (typeof clientUIFields)[number]

export const dynamicFields: Record<ClientUIFields, Component> = {
  timestamp: BaseInputDateTime,
  selection: BaseSelection,
  string: BaseInputText,
  boolean: BaseCheckbox,
  integer: BaseInputNumberInteger,
  float: BaseInputNumberFloat,
}
