
import Vue, { Component, PropType } from "vue"
import * as Sentry from "@sentry/browser"
import { mapActions } from "vuex"

// Local
import BaseCheckbox from "@/components/BaseCheckbox.vue"
import BaseInputText from "@/components/BaseInputText.vue"
import BaseInputDateTime from "@/components/BaseInputDateTime.vue"
import BaseRadio from "@/components/BaseRadio.vue"
import PageHeader from "@/components/PageHeader.vue"
import ReportCard from "@/components/ReportCard.vue"
import ReportCardForm from "@/components/ReportCardForm.vue"
import ReportCardHeader from "@/components/ReportCardHeader.vue"
import ReportModalGenerateActions from "@/components/ReportModalGenerateActions.vue"
import BaseModalListAction from "@/components/BaseModalListAction.vue"

import BaseSelection from "@/components/BaseSelection.vue"
import { ReportConfig } from "@/models/report-config"
import { ReportSpec } from "@/models/report-spec"
import { DateTime } from "luxon"
import LoadingIcon from "@/components/LoadingIcon.vue"

import { parseFieldErrors, validateObject } from "@/validation"
import { DefinedError, ErrorObject } from "ajv"
import { dynamicFields } from "@/dynamic-fields"
import { devOnlyError } from "@/logging"

interface Data {
  tempConfig: any
  saving: boolean
  deleting: boolean
  allFormErrors: ErrorObject[]
  fieldErrors: object
  formIsValid: boolean
  showGenerateDialog: boolean
  actionInProgress: boolean
  dynamicFields: Record<string, Component>
  isDevelopment: boolean
  isProductionDB: boolean
  theme: string
}

export default Vue.extend({
  name: "ReportConfigLayout",
  components: {
    LoadingIcon,
    BaseSelection,
    BaseCheckbox,
    BaseInputText,
    BaseInputDateTime,
    BaseRadio,
    PageHeader,
    ReportCard,
    ReportCardForm,
    ReportCardHeader,
    ReportModalGenerateActions,
    BaseModalListAction,
  },
  props: {
    selectedSpec: {
      type: Object as PropType<ReportSpec>,
      required: true,
    },
    selectedConfig: {
      type: Object as PropType<ReportConfig>,
      required: false,
      default: null,
    },
    clearSelectionHandler: {
      type: Function,
      required: true,
    },
    saveConfigHandler: {
      type: Function,
      required: true,
    },
    deleteConfigHandler: {
      type: Function,
      required: true,
    },
    downloadReportHandler: {
      type: Function,
      required: false,
      default: () => undefined,
    },
    generateAndEmailHandler: {
      type: Function,
      required: false,
      default: () => undefined,
    },
    userId: {
      type: String,
      required: true,
    },
    siteTimezone: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data(): Data {
    return {
      tempConfig: {
        type: "",
        user: "",
        configName: "",
        subscribed: false,
        frequency: null,
        configCustomizations: {},
      },
      deleting: false,
      saving: false,
      allFormErrors: [],
      formIsValid: true,
      showGenerateDialog: false,
      actionInProgress: false,
      dynamicFields: dynamicFields,
      fieldErrors: {},
      isDevelopment: process.env.NODE_ENV === "development",
      isProductionDB:
        process.env.VUE_APP_FIREBASE_PROJECT === "scaffoldtracker",
      theme: process.env.VUE_APP_THEME,
    }
  },
  computed: {
    computedConfigTitle(): string {
      return this.selectedConfig ? "Edit Report" : "Create New Report"
    },
    deleteDisabled(): boolean {
      return this.selectedConfig == null
    },
    computedSubtitle(): string {
      if (typeof this.selectedConfig?.timestampLastModified === "string") {
        const localTime = DateTime.fromISO(
          this.selectedConfig.timestampLastModified
        ).toLocaleString(DateTime.DATETIME_MED)
        return `${this.selectedSpec.reportName} | ${localTime}`
      } else {
        return this.selectedSpec.reportName
      }
    },
  },
  methods: {
    ...mapActions("messagesModule", ["addMsg"]),
    async deleteAndNavBack(): Promise<void> {
      this.deleting = true
      this.actionInProgress = true
      try {
        await this.deleteConfigHandler(this.selectedConfig)
        this.clearSelectionHandler()
      } finally {
        this.deleting = false
        this.actionInProgress = false
      }
    },
    toggleModalGenerateActions() {
      this.validateForm()
      if (!this.formIsValid) return
      this.showGenerateDialog = !this.showGenerateDialog
    },
    validateForm(): boolean {
      const errors = validateObject(
        this.selectedSpec.configValidationSchema,
        this.tempConfig
      )
      if (!errors) {
        this.formIsValid = true
        // return value from validateObject can be null currently. So we want
        // to set the allFormErrors to an array instead of null.
        this.allFormErrors = []
      } else {
        this.allFormErrors = errors
        this.formIsValid = false
      }

      // Create a reactive data object to contain form validation errors keyed
      // by instancePath from the ErrorObjects.
      const instancePaths: Set<string> = new Set()
      this.allFormErrors.forEach((err: DefinedError) => {
        instancePaths.add(err.dataPath)
      })
      // Add the list of errors to each instance path key.
      const localFieldErrors = {}
      instancePaths.forEach((path) => {
        localFieldErrors[path] = parseFieldErrors(
          this.allFormErrors as DefinedError[],
          path
        )
      })
      this.fieldErrors = localFieldErrors
      return !errors
    },
    async wrapperSaveConfig(): Promise<void> {
      // To make sure the form validation is executed right before sending.
      this.validateForm()

      if (!this.formIsValid) {
        return
      }

      this.saving = true
      this.actionInProgress = true
      try {
        await this.saveConfigHandler(this.tempConfig)
      } catch (e) {
        devOnlyError("Unable to save configuration", e)
      } finally {
        this.saving = false
        this.actionInProgress = false
      }
    },
    /**
     * Handle updating UI when calling the generateAndEmailHandler callback.
     */
    async wrapperGenerateEmailAction() {
      this.actionInProgress = true
      try {
        await this.generateAndEmailHandler(this.tempConfig)
        this.addMsg(["Email has been sent", "success"])
      } catch (e) {
        if (this.isProductionDB) {
          Sentry.captureException(e)
        } else {
          // eslint-disable-next-line no-console
          console.error(e)
        }
        this.addMsg([
          "Something went wrong. We've been notified but please contact support if the error persists",
          "error-dark",
        ])
      } finally {
        this.actionInProgress = false
        this.toggleModalGenerateActions()
      }
    },
    /**
     * Handle updating UI when calling the generateAndEmailHandler callback.
     */
    async wrapperGenerateDownloadAction() {
      this.actionInProgress = true
      try {
        await this.downloadReportHandler(this.tempConfig, true)
        this.addMsg(["Report generated for download", "success"])
      } catch (e) {
        if (this.isProductionDB) {
          Sentry.captureException(e)
        } else {
          // eslint-disable-next-line no-console
          console.error(e)
        }
        this.addMsg([
          "Something went wrong. We've been notified but please contact support if the error persists",
          "error-dark",
        ])
      } finally {
        this.actionInProgress = false
        this.toggleModalGenerateActions()
      }
    },
  },
  mounted() {
    const appFlavor = process.env.VUE_APP_FLAVOR

    this.tempConfig.type = this.selectedSpec.id
    if (this.selectedConfig != null) {
      this.tempConfig.id = this.selectedConfig.id
      this.tempConfig.user = this.selectedConfig.user
      this.tempConfig.configName = this.selectedConfig.configName
      this.tempConfig.subscribed = this.selectedConfig.subscribed
      this.tempConfig.frequency = this.selectedConfig.frequency
      this.tempConfig.configCustomizations = {
        ...this.selectedConfig.configCustomizations,
      }
      if (typeof this.selectedConfig.whiteLabel === "string") {
        this.tempConfig.whiteLabel = this.selectedConfig.whiteLabel
      } else {
        this.tempConfig.whiteLabel = appFlavor
      }
    } else {
      // New Config Defaults
      this.tempConfig.user = this.userId
      this.tempConfig.subscribed = false
      this.tempConfig.frequency = "monthly"
      this.tempConfig.whiteLabel = appFlavor

      // Set dynamic fields to default values
      for (const [fieldName, customizationItem] of Object.entries(
        this.selectedSpec.customizations
      )) {
        // Required to add new REACTIVE properties to an object.
        this.$set(
          this.tempConfig.configCustomizations,
          fieldName,
          customizationItem["default"]
        )
      }
    }
  },
})
