
import Vue from "vue"
import { mdiInformation } from "@mdi/js"

export default Vue.extend({
  name: "AlertInfo",
  data() {
    return {
      mdiInformation,
    }
  },
})
