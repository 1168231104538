
import Vue, { PropType } from "vue"
import { mdiEmail, mdiArrowLeft, mdiFileUpload } from "@mdi/js"

// Local
import IconSvg from "@/components/IconSvg.vue"
import ReportCard from "@/components/ReportCard.vue"
import ReportCardHeader from "@/components/ReportCardHeader.vue"
import ReportListConfigItem from "@/components/ReportListConfigItem.vue"
import ReportListDataItem from "@/components/ReportListDataItem.vue"
import ReportListSpecItem from "@/components/ReportListSpecItem.vue"
import AlertInfo from "@/components/AlertInfo.vue"
import PageHeader from "@/components/PageHeader.vue"

import { ReportData } from "@/models/report-data"
import { ReportConfig } from "@/models/report-config"
import { ReportSpec } from "@/models/report-spec"

type GenerateHandler = (configIndex: number) => void

export default Vue.extend({
  name: "ReportsListLayout",
  components: {
    PageHeader,
    AlertInfo,
    IconSvg,
    ReportListConfigItem,
    ReportListDataItem,
    ReportListSpecItem,
    ReportCardHeader,
    ReportCard,
  },
  props: {
    reportSpecs: {
      type: Object as PropType<Record<string, ReportSpec>>,
      required: true,
    },
    reportConfigs: {
      type: Array as PropType<ReportConfig[]>,
      required: true,
    },
    reportData: {
      type: Array as PropType<ReportData[]>,
      required: true,
    },
    editHandler: {
      type: Function,
      required: true,
    },
    generateHandler: {
      type: Function as PropType<GenerateHandler>,
      required: true,
    },
    navBackHandler: {
      type: Function,
      required: true,
    },
    navCreateNewConfigHandler: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      mdiEmail,
      mdiArrowLeft,
      mdiFileUpload,
    }
  },
})
