
import Vue from "vue"
import { DateTime } from "luxon"

export default Vue.extend({
  name: "BaseInputDate",
  props: {
    value: {
      type: String,
    },
    inputStyles: {
      type: String,
      required: false,
      default: "",
    },
    minDate: {
      type: String,
      required: false,
      default: undefined,
    },
    maxDate: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    parseDate(input: string): string {
      const dt = DateTime.fromISO(input).toISODate()
      return dt
    },
  },
})
