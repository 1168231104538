
export default {
  name: "BaseInputNumberInteger",
  props: {
    value: {
      type: Number,
    },
    inputStyles: {
      type: String,
      required: false,
      default: "",
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    /**
     * Will return 0 if the event value cannot be parsed as an integer.
     */
    parseInputAsFloat(event) {
      const result = parseFloat(event.target.value)
      return result ? result : 0
    },
  },
}
