
import Vue from "vue"
import IconSvg from "@/components/IconSvg.vue"
import { mdiArrowLeft } from "@mdi/js"

export default Vue.extend({
  name: "PageHeader",
  components: { IconSvg },
  data() {
    return {
      mdiArrowLeft,
    }
  },
})
