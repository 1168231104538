
import Vue from "vue"
export default Vue.extend({
  name: "WrapperMaxWidth",
  props: {
    classOverride: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      defaultClasses: "tw-max-w-screen-md tw-mx-auto",
    }
  },
})
