
import Vue from "vue"

// Libs
import { mdiChevronRight } from "@mdi/js"

// Local
import { getReportIcon } from "@/reports"
import IconSvg from "@/components/IconSvg.vue"

export default Vue.extend({
  name: "ReportListSpecItem",
  components: { IconSvg },
  props: {
    reportName: {
      type: String,
      required: true,
    },
    reportType: {
      type: String,
      required: true,
    },
    reportDescription: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      mdiChevronRight,
      getReportIcon,
    }
  },
})
