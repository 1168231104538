
import Vue from "vue"
import { onClickOutside } from "@vueuse/core"

// Local
import { useScrollLock } from "@/composables/scroll-lock"
import LoadingIcon from "@/components/LoadingIcon.vue"
const scrollLock = useScrollLock()

export default Vue.extend({
  name: "ReportModalGenerateActions",
  components: { LoadingIcon },
  props: {
    contentId: {
      type: String,
      required: true,
    },
    toggleHandler: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      clickOutsideListener: null,
    }
  },
  mounted() {
    scrollLock.enable()
    const generateActionsModal = document.getElementById(`${this.contentId}`)
    this.clickOutsideListener = onClickOutside(
      generateActionsModal,
      this.toggleHandler
    )
    // Bring into focus for navigation so the Div can capture keyboard events.
    generateActionsModal.focus()
  },
  beforeDestroy() {
    scrollLock.disable()
    // Remove event listeners.
    if (this.clickOutsideListener != null) {
      this.clickOutsideListener()
    }
  },
})
