
// Libs
import Vue from "vue"
import { DateTime } from "luxon"

// Local
import BaseInputTime from "@/components/BaseInputTime.vue"
import BaseInputDate from "@/components/BaseInputDate.vue"

export default Vue.extend({
  name: "BaseInputDateTime",
  components: {
    BaseInputDate,
    BaseInputTime,
  },
  props: {
    value: {
      type: String,
    },
    inputStyles: {
      type: String,
      required: false,
      default: "",
    },
    min: {
      type: String,
      required: false,
      default: undefined,
    },
    max: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      timePart: "",
      datePart: "",
    }
  },
  computed: {
    combinedDateTime(): string {
      if (
        typeof this.datePart === "string" &&
        typeof this.timePart === "string"
      ) {
        // This will create a DateTime from the string with the local machine's
        // timezone set, then create the ISO string with offset.
        return DateTime.fromISO(`${this.datePart}T${this.timePart}`)
          .setZone("UTC")
          .toISO()
      } else {
        return ""
      }
    },
    minDate(): string | undefined {
      const isoString = DateTime.fromISO(this.min)
      // return if it wasn't a valid ISO date string.
      if (!isoString.isValid) return
      return isoString.toISODate()
    },
    maxDate(): string | undefined {
      const isoString = DateTime.fromISO(this.max)
      // return if it wasn't a valid ISO date string.
      if (!isoString.isValid) return
      return isoString.toISODate()
    },
    // todo: min and max time?
  },
  methods: {
    updateDate(eventValue) {
      this.datePart = eventValue
      this.$emit("input", this.combinedDateTime)
    },
    updateTime(eventValue) {
      this.timePart = eventValue
      this.$emit("input", this.combinedDateTime)
    },
  },
  mounted() {
    if (typeof this.value === "string" && Date) {
      const isoString = DateTime.fromISO(this.value)
      // return if it wasn't a valid ISO date string.
      if (!isoString.isValid) return
      this.timePart = DateTime.fromISO(this.value).toISOTime().split("-")[0]
      this.datePart = DateTime.fromISO(this.value).toISODate()
    }
  },
  watch: {
    value(newVal) {
      if (typeof newVal === "string") {
        const isoString = DateTime.fromISO(this.value)
        // return if it wasn't a valid ISO date string.
        if (!isoString.isValid) return
        this.timePart = DateTime.fromISO(newVal).toISOTime().split("-")[0]
        this.datePart = DateTime.fromISO(newVal).toISODate()
      }
    },
  },
})
